<template>
    <ValidationObserver ref="form">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="number_of_installments" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('number_of_promissory')">
                        <b-form-input
                            type="number"
                            min="1" max="12"
                            v-model="form.number_of_installments"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="due_date" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('due_date')">
                        <b-form-input
                            type="date"
                            v-model="form.due_date"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="day_interval" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('day_interval')">
                        <b-form-input
                            type="number"
                            v-model="form.day_interval"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('installment_amount')">
                        <b-form-input
                            type="number"
                            min="0" step=".01"
                            v-check-min-max-value
                            v-model="form.amount"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payer" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payer')">
                        <b-form-input v-uppercase
                            v-model="form.payer"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payer_national_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payer_national_id')">
                        <b-form-input
                            v-model="form.payer_national_id"
                            v-mask="'###########'"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payer_address" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payer_address')">
                        <b-form-input
                            v-model="form.payer_address"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payer_phone" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payer_phone')">
                        <b-form-input
                            v-model="form.payer_phone"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <b-form-group :label="$t('guarantee')">
                    <b-form-input v-uppercase
                        v-model="form.guarantee"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <b-form-group :label="$t('guarantee_national_id')">
                    <b-form-input
                        v-model="form.guarantee_national_id"
                        v-mask="'###########'"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <b-form-group :label="$t('guarantee_address')">
                    <b-form-input
                        v-model="form.guarantee_address"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="related_number" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('promissory_no')">
                        <b-form-input
                            type="number"
                            autocomplete="off"
                            v-model="form.related_number"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-4 col-sm-6">
                <div class="container d-flex h-100">
                    <div class="row justify-content-center align-self-center mt-1">
                        <b-button @click="submitForm"
                                  type="button"
                                  variant="outline-primary"
                        >
                            <i class="ri-add-line mr-2"></i>{{ $t('add').toUpper() }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="rows.length>0">
            <div class="col-12">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('due_date').toUpper()}}</th>
                        <th>{{ $t('amount') }}</th>
                        <th>{{ $t('payer') }}</th>
                        <th>{{ $t('payer_national_id').toUpper()}}</th>
                        <th>{{ $t('payer_address') }}</th>
                        <th>{{ $t('payer_phone') }}</th>
                        <th>{{ $t('promissory_no') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,i) in rows" :key="i">
                        <td>
                            <b-form-input
                                type="date"
                                v-model="row.due_date">
                            </b-form-input>
                        </td>
                        <td>
                            <b-form-input
                                type="number"
                                @update="amountUpdated"
                                v-model="row.amount">
                            </b-form-input>
                        </td>
                        <td>{{ row.payer }}</td>
                        <td>{{ row.payer_national_id }}</td>
                        <td>{{ row.payer_address }}</td>
                        <td>{{ row.payer_phone }}</td>
                        <td>{{ row.related_number }}</td>
                        <td class="w-40">
                            <b-button @click="deleteRow(row.id)"
                                      type="button"
                                      variant="danger"
                            >
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentService from "@/services/PaymentService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        paymentPlan: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            form: {
                day_interval: 30,
                number_of_installments: 1,
                related_number: 1
            },
            rows: [],
            total: 0,
            index:0,
            related_id:0,
            processedCount:0,
            failed:[]
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
        },
        async submitForm() {
            if(this.checkPermission('payment_promissorynote')){
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    let formData = {
                        payment_plan_id: this.paymentPlan.payment_plan.id,
                        amount: this.form.amount,
                        currency: this.paymentPlan.payment_plan.currency,
                        number_of_installments: this.form.number_of_installments,
                        due_date: this.form.due_date,
                        day_interval: this.form.day_interval,
                        related_number: this.form.related_number,
                        payer: this.form.payer,
                        payer_national_id: this.form.payer_national_id,
                        payer_address: this.form.payer_address,
                        payer_phone: this.form.payer_phone,
                        guarantee: this.form.guarantee,
                        guarantee_national_id: this.form.guarantee_national_id,
                        guarantee_address: this.form.guarantee_address,
                        currency_rate: this.paymentPlan.payment_plan.currency_rate,
                    };

                    PaymentService.storePromissoryNote(formData, true)
                                  .then(response => {
                                      response.data.data.forEach(item=>{
                                          item.payer=this.form.payer;
                                          item.payer_national_id=this.form.payer_national_id;
                                          item.payer_address=this.form.payer_address;
                                          item.payer_phone=this.form.payer_phone;
                                          item.id=this.index++;
                                          this.rows.push(item);
                                      });
                                      this.total += parseFloat(formData.amount) * parseInt(formData.number_of_installments);
                                      this.$emit('promissoryAdded', this.total);
                                      this.form.amount = null;
                                      this.form.due_date = null;
                                      this.form.ralated_number = null;
                                      this.form.account_number = null;
                                      this.form.payer = null;
                                      this.form.payer_national_id = null;
                                      this.form.payer_address = null;
                                      this.form.payer_phone = null;
                                      this.form.guarantee = null;
                                      this.form.guarantee_national_id = null;
                                      this.form.guarantee_address = null;
                                      this.$refs.form.reset();
                                  })
                                  .catch(e => {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                      if(e.status===422 && e.data.errors){
                                          Object.keys(e.data.errors).forEach(key=>{
                                              this.$refs.form.errors[key].push(e.data.errors[key][0]);
                                          });
                                      }
                                  });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        deleteRow(id) {
            this.rows = this.rows.filter(function(item){
                return item.id!=id;
            });

            this.amountUpdated();
        },
        amountUpdated(){
            this.total = 0;
            this.rows.forEach(item=>{
                this.total += parseFloat(item.amount);
            })
            this.$emit('promissoryAdded', this.total);
        },
        save(receiptNumber, explanation) {
            let row=this.rows[this.processedCount];
            row.receipt_number = receiptNumber;
            row.explanation=explanation;
            row.number_of_installments=1;
            row.day_interval=0;
            if(this.related_id>0){
                row.related_id=this.related_id;
            }
            PaymentService.storePromissoryNote(row, false)
                .then(response => {
                    this.related_id = response.data.data[0].related_id;
                    this.processedCount++;
                })
                .catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                    this.failed.push(row);
                })
                .finally(()=>{
                    if(this.processedCount===this.rows.length){
                        this.rows = this.failed;
                        this.$emit('promissoryDone', true);
                    }
                    else {
                        this.save(receiptNumber, explanation)
                    }
            });
        }

    },
}
</script>
