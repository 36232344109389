import FinanceApi from '@/services/FinanceApi';

const getPaymentPlan = async (id, paymentId) => {
    return FinanceApi.get('/customers/payment/plan/' + id + '/' + paymentId);
}

const getStudentPayments = async (id) => {
    return FinanceApi.get('/customers/payments?student_program_id=' + id);
}

const getRegistrationPayments = async (id) => {
    return FinanceApi.get('/customers/payments?registration_id=' + id);
}

const approveStudent = async (studentNumber, studentProgramId) => {
    return FinanceApi.get('/approve/student/program/' + studentNumber + '/' + studentProgramId);
}

const approveRegistration = async (studentNumber, registrationId, academicYear) => {
    return FinanceApi.get('/approve/registration/' + studentNumber + '/' + registrationId + '/' + academicYear);
}

const getMyPayments = async (id) => {
    return FinanceApi.get('/student/payments/' + id);
}

const createEpaymentLogin = async (data) => {
    return FinanceApi.post('/epayment/create/', data);
}

const patch = async (id, data) => {
    return FinanceApi.patch('/customers/'+id, data);
}

export default {
    getPaymentPlan,
    getRegistrationPayments,
    getStudentPayments,
    approveStudent,
    approveRegistration,
    getMyPayments,
    createEpaymentLogin,
    patch
}
